import {cx} from 'class-variance-authority'
import type {DetailedHTMLProps, HTMLAttributes, ReactNode} from 'react'
import {Children, isValidElement, useEffect, useRef, useState} from 'react'
import {CheckIcon, CopyIcon} from './icons'

interface PreCodeBlockProps extends DetailedHTMLProps<HTMLAttributes<HTMLPreElement>, HTMLPreElement> {
  children?: ReactNode
}

export const PreCodeBlock: React.FC<PreCodeBlockProps> = ({children, ...props}) => {
  const preRef = useRef<HTMLPreElement>(null)
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    if (preRef.current) {
      const code = preRef.current.innerText
      navigator.clipboard
        .writeText(code)
        .then(() => {
          console.log('Code copied to clipboard')
          setCopied(true)
        })
        .catch((err) => {
          console.error('Failed to copy code: ', err)
        })
    }
  }

  useEffect(() => {
    if (!copied) return
    const timeout = setTimeout(() => setCopied(false), 2000) // Reset after 2 seconds
    return () => clearTimeout(timeout)
  }, [copied])

  // Check if children contain a `code` element
  const hasCodeChild = Children.toArray(children).some((child) => isValidElement(child) && child.type === 'code')

  return (
    <div className="relative">
      <pre
        ref={preRef}
        {...props}
        className="border-radix-mauve5 bg-radix-mauve2 relative rounded-sm border px-0! py-4"
        rel="nofollow"
        itemScope
        itemType="https://schema.org/SoftwareSourceCode"
      >
        {children}
      </pre>
      {hasCodeChild && (
        <button
          onClick={handleCopy}
          className={cx(
            'shadow-radix-mauve2 absolute top-2 right-2 rounded-sm border px-2 py-1 font-mono text-sm shadow-md transition-colors duration-200',
            copied && 'border-radix-grass7 bg-radix-grass3 text-radix-grass11',
            !copied && 'border-radix-mauve7 bg-radix-mauve3 text-radix-mauve11 hover:border-radix-mauve8',
          )}
        >
          {copied && <CheckIcon className="w-4" />}
          {copied && <span className="sr-only">Copied!</span>}

          {!copied && <CopyIcon className="w-4" />}
          {!copied && <span className="sr-only">Copy code</span>}
        </button>
      )}
    </div>
  )
}
