import * as Toast from '@radix-ui/react-toast'
import {cx} from 'class-variance-authority'

export interface ToastMessage {
  id: number
  type: 'success' | 'error'
  data: string
}

const ToastRoot: React.FC<{message: ToastMessage}> = ({message}) => {
  return (
    <Toast.Root
      className={cx(
        'radix-toast flex items-start rounded-sm border p-4 leading-none',
        message.type === 'success' && 'border-radix-grass6 bg-radix-grass4 text-radix-grass12',
        message.type === 'error' && 'border-radix-red6 bg-radix-red4 text-radix-red12',
      )}
    >
      <div className="flex-1">
        <Toast.Title className="sr-only">
          {message.type === 'success' ? 'Success Notification' : 'Error Notification'}
        </Toast.Title>
        <Toast.Description className="text-sm">{message.data}</Toast.Description>
      </div>
      <Toast.Close aria-label="Close">
        <span aria-hidden>&#215;</span>
      </Toast.Close>
    </Toast.Root>
  )
}

export interface ToastProviderProps {
  children: React.ReactNode
  toast?: ToastMessage | null
}

export const ToastProvider: React.FC<ToastProviderProps> = ({children, toast}) => {
  return (
    <Toast.Provider swipeDirection="right">
      {children}
      <Toast.Viewport className="fixed top-0 right-0 z-50 flex w-96 max-w-full list-none flex-col gap-3 p-6" />

      {toast && <ToastRoot key={toast.id} message={toast} />}
    </Toast.Provider>
  )
}
