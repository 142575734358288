import {Link, useLocation} from '@remix-run/react'
import {ValidatedForm} from '@rvf/remix'
import {validator} from '~/routes/_marketing.cookies/route'

export const CookieBanner: React.FC = () => {
  const location = useLocation()

  if (location.search.includes('screenshot')) {
    return null
  }

  return (
    <div className="border-radix-mauve7 bg-radix-mauve3 fixed right-4 bottom-4 left-4 z-10 space-y-4 rounded-lg border p-8 sm:right-auto sm:bottom-8 sm:left-8 sm:max-w-xs">
      <div>
        We use{' '}
        <Link to="/cookies" className="text-radix-blue11 underline">
          cookies
        </Link>{' '}
        to understand how people use Depot.
      </div>
      <div className="flex items-center justify-between gap-4">
        <ValidatedForm method="post" validator={validator} action="/cookies" reloadDocument>
          <input type="hidden" name="allowed" value="no" />
          <button type="submit" className="text-radix-blue11 whitespace-nowrap underline">
            Reject cookies
          </button>
        </ValidatedForm>
        <ValidatedForm method="post" validator={validator} action="/cookies" reloadDocument>
          <input type="hidden" name="allowed" value="yes" />
          <button type="submit" className="text-radix-blue11 whitespace-nowrap underline">
            Accept cookies
          </button>
        </ValidatedForm>
      </div>
    </div>
  )
}
