import {MDXProvider} from '@mdx-js/react'
import {bytesToHex, randomBytes} from '@noble/hashes/utils'
import * as Tooltip from '@radix-ui/react-tooltip'
import type {LinksFunction, LoaderFunctionArgs} from '@remix-run/node'
import {data, redirect} from '@remix-run/node'
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useNavigation,
  useRouteError,
} from '@remix-run/react'
import {captureRemixErrorBoundaryError, withSentry} from '@sentry/remix'
import type {MDXComponents} from 'mdx/types'
import {posthog} from 'posthog-js'
import {StrictMode, useEffect, useState} from 'react'
import reactflow from 'reactflow/dist/style.css?url'
import {ImageWithCaption} from '~/components/Image'
import {PreCodeBlock} from '~/components/PreCodeBlock'
import type {ToastMessage} from '~/components/Toast'
import {CookieBanner} from './components/CookieBanner'
import {Progress} from './components/Progress'
import {PanelScrollProvider} from './components/ScrollablePanel'
import {ToastProvider} from './components/Toast'
import {HelpContextProvider} from './components/app/HelpPopover'
import {BuildVersionContext} from './hooks/useBuildVersion'
import {PostHogProvider} from './hooks/usePostHog'
import {usePrevious} from './hooks/usePrevious'
import styles from './tailwind.css?url'
import {getCookieConsent} from './utils/cookies.server'
import {getDiscordOnlineCount} from './utils/discord.server'
import {POSTHOG_API_TOKEN} from './utils/env.server'
import {identifySentryUser} from './utils/sentry.server'
import {getSession} from './utils/session.server'

export const links: LinksFunction = () => [
  {
    rel: 'preload',
    href: '/fonts/red-hat-text-latin-wght-normal.woff2',
    as: 'font',
    type: 'font/woff2',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'preload',
    href: '/fonts/red-hat-mono-latin-wght-normal.woff2',
    as: 'font',
    type: 'font/woff2',
    crossOrigin: 'anonymous',
  },
  {rel: 'shortcut icon', href: '/favicon.svg', type: 'image/svg+xml'},
  {rel: 'stylesheet', href: styles},
  {rel: 'stylesheet', href: reactflow},
]

export interface LoaderData {
  toastMessage: ToastMessage | null
  posthogToken: string
  userID?: string
  email?: string
  lastVisitedOrganizationID?: string
  buildVersion?: string
  cookiesAllowed?: boolean
  nonce: string
  sentryTrace?: string
  sentryBaggage?: string
  discordOnlineCount: number
  isImpersonating: boolean
}

const mdxComponents: Readonly<MDXComponents> = {
  pre: (props) => <PreCodeBlock {...props} />,
  img: (props) => <ImageWithCaption {...props} />,
}

export async function loader({request, context}: LoaderFunctionArgs) {
  // http -> https redirect
  const url = new URL(request.url)
  const proto = request.headers.get('X-Forwarded-Proto') ?? url.protocol
  if (proto === 'http' && url.hostname !== 'localhost') {
    url.host = request.headers.get('X-Forwarded-Host') ?? request.headers.get('host') ?? url.host
    url.protocol = 'https:'
    return redirect(url.toString(), {headers: {'X-Forwarded-Proto': 'https'}})
  }

  const session = await getSession(request)
  const user = await session.getUser()
  const toastMessage = session.getToastMessage()
  const cookiesAllowed = Boolean(user) || getCookieConsent(request)
  const nonce = bytesToHex(randomBytes(16))

  const discordOnlineCount = await getDiscordOnlineCount()

  identifySentryUser(user)

  return data<LoaderData>(
    {
      posthogToken: POSTHOG_API_TOKEN,
      userID: user?.id,
      email: user?.email,
      lastVisitedOrganizationID: user?.lastVisitedOrganizationID ?? undefined,
      buildVersion:
        process.env.NODE_ENV === 'development' ? 'development' : (context?.buildVersion as string | undefined),
      cookiesAllowed: await cookiesAllowed,
      nonce,
      discordOnlineCount,
      isImpersonating: session.isImpersonating(),
      toastMessage: toastMessage ?? null,
    },
    {headers: await session.headers()},
  )
}

export default withSentry(function App() {
  const navigation = useNavigation()
  const location = useLocation()
  const loaderData = useLoaderData<typeof loader>()
  const {toastMessage, posthogToken, userID, cookiesAllowed} = loaderData
  const previousUserID = usePrevious(userID)
  const [nprogressState, setNprogressState] = useState({isAnimating: false, key: 0})

  const nonce = typeof document !== 'undefined' ? '' : loaderData.nonce
  const isImpersonating = loaderData.isImpersonating

  useEffect(() => {
    posthog.init(posthogToken, {api_host: 'https://p.depot.dev', ui_host: 'https://app.posthog.com'})
    if (posthog.has_opted_out_capturing()) {
      if (cookiesAllowed !== false) posthog.opt_in_capturing()
    } else if (cookiesAllowed === false || isImpersonating) {
      posthog.opt_out_capturing()
    }
  }, [posthogToken, cookiesAllowed, isImpersonating])

  // Reset Posthog on sign out or user change
  useEffect(() => {
    const userChanged = previousUserID && userID && previousUserID !== userID
    const userSignedOut = !userID && previousUserID
    if (userChanged || userSignedOut) posthog.reset()
  }, [userID, previousUserID])

  useEffect(() => {
    if (userID) posthog.identify(userID)
  }, [userID])

  useEffect(() => {
    posthog.capture('$pageview')
  }, [location])

  useEffect(() => {
    if (navigation.state === 'idle')
      setNprogressState((prev) => ({isAnimating: false, key: prev.isAnimating ? prev.key : prev.key ^ 1}))
    else setNprogressState((prev) => ({isAnimating: true, key: prev.isAnimating ? prev.key : prev.key ^ 1}))
  }, [navigation.state])

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />

        <meta property="og:site_name" content="Depot" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://depot.dev${location.pathname}`} />
        <meta property="twitter:site" content="@depotdev" />

        <link rel="apple-touch-icon" href="apple-touch-icon.png" />
        <link rel="icon" href="/favicon.ico" sizes="32x32" />
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <link rel="icon" href="/favicon-light.svg" type="image/svg+xml" media="(prefers-color-scheme: light)" />
        <link rel="icon" href="/favicon-dark.svg" type="image/svg+xml" media="(prefers-color-scheme: dark)" />

        {loaderData.sentryTrace && <meta property="sentry-trace" content={loaderData.sentryTrace} />}
        {loaderData.sentryBaggage && <meta property="baggage" content={loaderData.sentryBaggage} />}
        <Meta />
        <Links />
      </head>
      <body className="bg-radix-mauve-black text-radix-mauve12">
        <StrictMode>
          <PostHogProvider client={posthog}>
            <ToastProvider toast={toastMessage}>
              <BuildVersionContext.Provider value={loaderData.buildVersion}>
                <Tooltip.Provider>
                  <PanelScrollProvider>
                    <MDXProvider components={mdxComponents}>
                      <HelpContextProvider>
                        <Progress isAnimating={nprogressState.isAnimating} key={nprogressState.key} />
                        {cookiesAllowed === undefined && <CookieBanner />}
                        <Outlet />
                      </HelpContextProvider>
                    </MDXProvider>
                  </PanelScrollProvider>
                </Tooltip.Provider>
              </BuildVersionContext.Provider>
            </ToastProvider>
            <ScrollRestoration nonce={nonce} />
            <Scripts nonce={nonce} />
          </PostHogProvider>
        </StrictMode>
      </body>
    </html>
  )
})

export function ErrorBoundary() {
  const error = useRouteError()
  const location = useLocation()
  const nonce = typeof document !== 'undefined' ? '' : (error as any).nonce

  captureRemixErrorBoundaryError(error)

  if (isRouteErrorResponse(error)) {
    return (
      <html>
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <title>404 Not Found</title>
          <Meta />
          <Links />
        </head>
        <body className="bg-radix-mauve-black text-radix-mauve12 flex min-h-screen items-center justify-center">
          <div className="mx-4 space-y-6">
            <h1 className="text-3xl font-bold">Oops, that page is not found!</h1>

            <p className="max-w-md text-lg">
              We are sorry, but the page you are looking for cannot be found. Try heading to the{' '}
              <Link to="/" className="text-radix-indigo11">
                homepage
              </Link>
              ,{' '}
              <Link to="/docs" className="text-radix-indigo11">
                documentation
              </Link>
              , or{' '}
              <a href="mailto:help@depot.dev" className="text-radix-indigo11">
                contact us
              </a>{' '}
              if you need help.
            </p>
            <div className="border-radix-amber6 bg-radix-amber2 -mx-4 space-y-2 border-t-2 border-b-2 p-4">
              <div className="text-lg">Error Details</div>
              <code className="block text-sm">
                {error.status} {error.statusText}
              </code>
            </div>
          </div>
          <Scripts nonce={nonce} />
        </body>
      </html>
    )
  }

  let errorMessage = 'An unexpected error has occurred'
  if (error instanceof Error) {
    errorMessage = error.stack ?? error.message
  }

  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <title>Oops! Unexpected error</title>
        <Meta />
        <Links />
      </head>
      <body className="bg-radix-mauve-black text-radix-mauve12 flex min-h-screen items-center justify-center">
        <div className="mx-4 max-w-md space-y-6">
          <h1 className="text-3xl font-bold">Oops, we have encountered an unexpected error!</h1>

          <p className="text-lg">
            We are sorry, but we have encountered an unexpected error - the error has been reported, but feel free to{' '}
            <a href="mailto:help@depot.dev" className="text-radix-indigo11">
              contact us
            </a>{' '}
            if you need help.
          </p>
          <div className="border-radix-amber6 bg-radix-amber2 -mx-4 space-y-2 border-t-2 border-b-2 p-4">
            <div className="text-lg">Error Details</div>
            <code className="block text-sm">{errorMessage}</code>
            <code className="block text-sm">Location {location.pathname}</code>
          </div>
        </div>
        <Scripts nonce={nonce} />
      </body>
    </html>
  )
}
